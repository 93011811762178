@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.MyAccountCustomerTable {
    .Button {
        --button-height: 47px;
        --button-hover-height: 47px;
        --button-border-radius: 7px;
        --button-hover-border-radius: 7px;
        --button-padding: 47px;
        --button-hover-padding: 47px;
        letter-spacing: 0.07em;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        font-family: $font-primary;

        + .Button {
            @include desktop {
                margin-left: 18px;
            }
        }
    }
}
