$white: #ffffff;
$grey10: #f9f9f9;
$grey20: #f3f3f3;
$grey30: #e7e7e7;
$grey40: #d1d1d1;
$grey50: #a1a1a1;
$grey60: #8a8989;
$grey70: #797979;
$grey80: #484848;
$black: #000000;
$purple: #f4f3fd;
$purple5: #eae8fd;
$purple10: #eae8fd;
$purple20: #d9d0fc;
$purple30: #af9ef0;
$purple40: #8b77da;
$purple50: #5b45b1;
$purple60: #513ba7;
$purple70: #47309e;
$purple80: #3e2b88;
$purple90: #2b177a;
$orange5: #f9d7cb;
$orange10: #f7caba;
$orange20: #f4b8a3;
$orange30: #f1a184;
$orange40: #adcee4;
$orange50: #e65724;
$orange60: #b95029;
$orange70: #974629;
$orange80: #7c3e28;
$orange90: #663625;

$red5: #ffebec;
$red10: #f9cdd1;
$red20: #f6b3b9;
$red30: #f39aa1;
$red40: #ef7882;
$red50: #ea4b59;
$red60: #d23542;
$red70: #af303b;
$red80: #902e36;
$red90: #762b32;

$blue5: #e3f2ff;
$blue10: #b9dfff;
$blue20: #88c8ff;
$blue30: #5eb4ff;
$blue40: #0088ff;
$blue50: #005caa;
$blue60: #0e4c82;
$blue70: #11406a;
$blue80: #123656;
$blue90: #122e46;

$yellow5: #fbfaef;
$yellow10: #f6eec1;
$yellow20: #f1e390;
$yellow30: #f4d955;
$yellow40: #efc727;
$yellow50: #efbb21;
$yellow60: #eea71c;
$yellow70: #f0991a;
$yellow80: #f07810;
$yellow90: #c86209;

$green5: #e0f8f3;
$green10: #caf1ea;
$green20: #aee2d9;
$green30: #93d8cc;
$green40: #6fccbb;
$green50: #42b8a3;
$green60: #429284;
$green70: #3c796e;
$green80: #35645c;
$green90: #2f534d;

$red-light: #ffdddd;
$red-light2: #ea4b59;
$red: #dd2d2d;
$success-background-color: $green5;
$success-color: $green70;
$error-background-color: $red-light;
$error-color: $red;

$default-primary-base-color: $blue50;
$default-primary-dark-color: $blue60;
$default-primary-light-color: $blue50;
$default-secondary-base-color: $yellow50;
$default-secondary-dark-color: $yellow60;
$default-secondary-light-color: $yellow40;
$default-tertiary-base-color: $green50;
$default-tertiary-dark-color: $green60;
$default-tertiary-light-color: $green40;
$grey-dark: $grey50;
$grey: #aaafb6;
$grey2: #e0e3e6;
$grey3: #797979;
$grey4: #d1d1d1;
$grey5: #e7e7e7;
$grey6: #c4c4c4;
$grey7: #8a8989;
$grey-light: $grey20;
$grey-lightest: #edf0f3;
$font-muli: 'Gotham', sans-serif;
$font-standard-size: 62.5%;
$font-primary: 'Gotham', sans-serif;
$font-secondary: $font-muli;
