@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --my-account-heading-color: #{$default-primary-base-color};
    --my-account-content-border: 1px solid #{$default-primary-base-color};
    --my-account-table-head-color: #{$default-primary-base-color};
    --my-account-table-body-font-family: #{$font-secondary};
    --my-account-subheading-color: #{$grey-dark};
}

.MyAccount {
    @include mobile {
        margin-top: 0;
    }
    &-Button,
    .Button {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 0.07em;
        --button-radius: 7px;
        --button-hover-radius: 7px;
        --button-padding: 47px;
        --button-hover-padding: 47px;
        --button-height: 47px;
        --button-hover-height: 47px;
    }

    &-Wrapper {
        @include desktop {
            grid-column-gap: 50px;
        }

        @include mobileAndTablet {
            grid-column-gap: 28px;
            padding: 0;
            grid-template-columns: 1fr;
        }
    }

    &-TabContent {
        @include desktop {
            border: none;
            padding: 0 0 20px 0;
            grid-row: span 2;
        }

        @include tablet {
            margin-top: 20px;
            padding: 0 14px;
        }

        @include mobile {
            overflow-x: hidden;
            margin-top: 20px;
        }
    }

    &-Heading {
        @include from-tablet {
            font-size: 32px;
            line-height: 42px;
            color: var(--my-account-heading-color);
            margin-bottom: 32px;
        }
        @include mobileAndTablet {
            margin-block-end: 28px;
            display: none;
        }
    }

    .KeyValueTable {
        &-Heading {
            font-size: var(--table-font-size);
            line-height: 140%;
            letter-spacing: 0.07em;
        }

        tbody {
            th,
            td {
                font-family: $font-secondary;
                font-weight: 500;
                font-size: var(--table-font-size);
                line-height: 150%;
                letter-spacing: 0.05em;
                word-break: break-word;
            }

            td {
                font-weight: normal;
            }

            tr {
                border-color: $grey5;
            }
        }
    }

    &-SubHeading {
        font-size: 30px;
        color: var(--my-account-subheading-color);
        font-weight: 700;
    }
}
