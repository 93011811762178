@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.MyAccountOverlay .MyAccountOverlay-Additional_state_forgotPasswordSuccess,
.MyAccountOverlay .Form .Button:not(.Button_likeLink.MyAccountOverlay-ForgotPassword),
.SwipeableTemporaryDrawer-Content .MyAccountOverlay-Additional_state_forgotPasswordSuccess,
.SwipeableTemporaryDrawer-Content .Form .Button:not(.Button_likeLink.MyAccountOverlay-ForgotPassword) {
    --button-border-radius: 100px;
    --button-hover-border-radius: 100px;
    box-shadow: none;
}

.MyAccountOverlay {
    &-Action {
        &::-webkit-scrollbar-track {
            background-color: $default-secondary-base-color;
        }
    }
}
