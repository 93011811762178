@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.MyAccountReturns {
    &-Button.Button {
        margin-bottom: 40px;
        @include desktop {
            position: absolute;
            inset-block-start: -55px;
            inset-inline-end: 0;
            top: -73px;
            margin-bottom: 0;
        }
    }
}
