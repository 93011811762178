@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.MyAccountNewsletterSubscription {
    .Consents {
        .Field-CheckboxLabel {
            p {
                font-size: 12px;
            }
        }

        &-Field {
            .Consents-Label_isRequired {
                font-size: 12px;
            }
        }
    }
}
